/**
 * Created by km arti on 18/07/23.
 */

import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { urls } from './urls';


@Injectable({
    providedIn: 'root'
})
export class DataService {

    // private apiResponseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // private apiResponseSource = new EventEmitter<any>();
    // filterClicked = this.apiResponseSource.asObservable();
    // searchFilterChange = new Subject<any>();
    // private viewProductResponseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // public viewProductResponse$: Observable<any> = this.viewProductResponseSubject.asObservable();
    // private viewProductResponseSubject = new BehaviorSubject<any>(null);
    // viewProductResponse$ = this.viewProductResponseSubject.asObservable();

    private searchFilterChangeSubject = new BehaviorSubject<any>(null);
    searchFilterChange$: Observable<any> = this.searchFilterChangeSubject.asObservable();
    private destroy$: Subject<void> = new Subject<void>(); // Subject to handle subscription cleanup


    viewProductResponse = {};

    setSelectedCountryName(country: any) {
        localStorage.setItem('countryName', country);
    }
    setSelectedCountryCode(countryCode: any) {
        localStorage.setItem('countryCode', countryCode);
    }

    constructor(private http: HttpClient) {


    }

    onSpinner() {
        const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
        const curtain = <HTMLDivElement>document.getElementById('curtain');
        spinner.style.display = 'block';
        curtain.style.display = 'block';
    }

    offSpinner() {
        const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
        const curtain = <HTMLDivElement>document.getElementById('curtain');
        spinner.style.display = 'none';
        curtain.style.display = 'none';
    }


    callRestful(type: string, url: string, body?: any, headerObject?: any, includeHeader?: boolean, params?: HttpParams, hideSpinner?: boolean): Observable<any> {
        if (!(hideSpinner != null && hideSpinner == false)) {
            this.onSpinner();
        }

        var headerOption = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, TRACE, OPTIONS, PATCH',
            'responseType': 'json'
        };
        if (includeHeader == null || includeHeader === true) {
        }

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        if (headerObject && headerObject['moglixSupplierId']) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'moglixSupplierId': headerObject['moglixSupplierId']
            });
        }
        if (headerObject && headerObject['tenantId']) {
            let tempheaders = new HttpHeaders();
            headers = tempheaders.set('Content-Type', 'application/json').set('tenantId', headerObject['tenantId']);

        }

        switch (type) {
            case 'GET':
                return this.http.get(url,
                    {
                        responseType: 'json',
                        params: params,
                        headers: headers
                    })
                    .pipe(
                        catchError(this.catchError.bind(this))
                    );
            case 'JSON':
                return this.http.get(url,
                    {
                        responseType: 'text',
                        params: params

                    })
                    .pipe(
                        catchError(this.catchError.bind(this))
                    );
            case 'POST':
                return this.http.post<any>(url, body, { headers: headers })
                    .pipe(
                        catchError(this.catchError.bind(this))
                    );
            case 'GET_MSN':
                return this.http.get(url, { headers: headers })
                    .pipe(
                        catchError(this.catchError.bind(this))
                    );
            case 'DELETE':
                return this.http.get(url,
                    {
                        responseType: 'json',
                        params: params

                    })
                    .pipe(
                        catchError(this.catchError.bind(this))
                    );

            default:
                return null;
        }
    }


    catchError(err) {
        this.offSpinner();
        if (err.message) {
            this.showMessage('error', err.message);
            //alert("Error occurred, " + err.message + " try after some time");
        } else {
            this.showMessage('error', 'Network error accouured');
            //alert("Network error accouured");
        }
    }


    showMessage(cssClass, msg) {

        const x = document.getElementById('alert-box');
        if (document.getElementById('paraText') != null) {
            x.removeChild(document.getElementById('paraText'));
        }
        const para = document.createElement('span');
        para.setAttribute('id', 'paraText');
        para.innerHTML = msg;
        let classType = '';
        x.appendChild(para);
        if (cssClass === 'error') {
            classType = 'show-error';
        }
        if (cssClass === 'success') {
            classType = 'show-sucess';
        }
        if (cssClass === 'info') {
            classType = 'show-info';
        }
        if (cssClass === 'downloadMSG') {
            classType = 'show-downloadMSG';
        }
        x.className = classType;
        setTimeout(function () {
            x.className = x.className.replace(classType, '');
        }, 5000);
    }

    // session storage
    createSession(userId, email, token) {
        const pass = 'pass';
        localStorage.setItem('email', email);
        localStorage.setItem('userId', userId);
        localStorage.setItem('token', token);
    }
    page1SelectedCagtegoy() {
        return JSON.parse(localStorage.getItem('page1slectedCategory'))
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getSelectedCountryCode() {
        return JSON.parse(localStorage.getItem('countryCode'));
    }

    getSelectedCountryName() {
        return (localStorage.getItem('countryName'));

    }
    destorySession() {
        // localStorage.removeItem("email");
        // localStorage.removeItem("name");
        localStorage.clear();
    }

    getEmail() {
        return localStorage.getItem('email');
    }


    setviewProductResponse(productveiwData) {

        this.viewProductResponse = productveiwData;


    }
    getviewProductResponse() {
        return this.viewProductResponse
    }

    getviewHeaderTemplate() {
        return this.viewProductResponse["data"]["viewHeaderTemplate"];
    }
    getattributeFilterBuckets() {
        return this.viewProductResponse["data"]["attributeFilterBuckets"];
    }

    getproductList() {
        return this.viewProductResponse["data"]["products"];
    }
    getCategoryL1List(data) {
        this.onSpinner()
        let url = urls.categoryL1
        return this.http.post(url, data)

    }

    getCategoryList(searchedCategories) {

        const categoryList: any = {};

        Object.keys(searchedCategories).forEach((categoryId) => {
            const categoryData = searchedCategories[categoryId];
            categoryList[categoryId] = {
                categoryName: categoryData.categoryName,
                productCount: categoryData.productCount,
            };
        });
        return categoryList;
    }
    getSearchedCategoryAPI(request) {
        let url = urls.platformdatprod + 'cassandraFeed/catalogCategory/categorySearch';

        return this.http.post(url, request)
    }
    getSearchedBrandAPI(request) {
        let url = urls.searchBrand + 'search/brands?pageNo=1&pageSize=1';

        return this.http.post(url, request)
    }
    getBreadCrumData(categoryID) {
        let url = urls.catalog + 'mapper/parentHierarchy?categoryCode=' + categoryID
        return this.http.get(url)
    }

    // Method to get the product view API data
    getProductViewAPI(url, request: any): Observable<any> {
        this.onSpinner()



            ;


        let data = this.http.post(url, request).pipe(
            catchError((error) => {
                this.offSpinner()
                this.showMessage("error", "Something went wrong with current request. Please try again later.")
                return throwError('Something went wrong with current request. Please try again later.');
            })
        );
        return data;

    }

    getAccordainAPIdata(categoryID) {
        this.onSpinner()
        const apiAccordain = urls.platformdatprod + 'cassandraFeed/category/getByCode?code=' + categoryID;
        return this.http.get(apiAccordain)
    }




    // Simulate API call with search text input
    simulateAPICall(searchText): Promise<any> {
        let apiUrl = urls.search + 'search/brands';

        const headers = new HttpHeaders().set('Authorization', 'your_api_key');
        //const body = { inputsearchString: searchText };

        return this.http.post(apiUrl, searchText).toPromise();

    }


    // Method to emit the search filter change only when the data changes
    emitSearchFilterChange(data: any): void {
        if (data !== this.searchFilterChangeSubject.getValue()) {
            this.searchFilterChangeSubject.next(data);
        }
    }




    // Method to clear the subscription and prevent memory leaks
    clearSubscription(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    catchThis(err) {
        this.offSpinner();
        if (err.message) {
            this.showMessage('error', "Event Tracker Failed...");
            //alert("Error occurred, " + err.message + " try after some time");
        } else {
            this.showMessage('error', 'Network error accouured');
            //alert("Network error accouured");
        }
    }
    eventTracker(eventSource: string, eventType: string, data: string, pageUrl: string) {
        let url: string = urls.eventTracker;
        let body = {
            "userId": this.getEmail(),
            "eventSource": eventSource.toUpperCase(),
            "eventType": eventType,
            "data": data,
            "pageUrl": pageUrl
        }



        this.callRest('POST', url, body, null, null, null, false).subscribe((data => {

            if (data.status) {
                console.log(eventType + " Event published successfully");
            } else {
                this.showMessage("error", "Event Tracker Failed...");
                console.log("Publishing " + eventType + " Event  failed");
            }
        }))



    }
    callRest(arg0: string, url: string, body: { userId: string; eventSource: string; eventType: string; data: string; pageUrl: string; }, arg3: null, arg4: null, arg5: null, arg6: boolean) {

        return this.http.post<any>(url, body)
            .pipe(
                catchError((this.catchThis.bind(this)))
            );

    }

}
