import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { urls } from '../urls';
import { GoogleAnalyticsServiceService } from '../google-analytics-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  invalid: boolean;


  constructor(private route: ActivatedRoute, private router: Router, private service: DataService, private _msalService: MsalService,private googleAnalytics:GoogleAnalyticsServiceService) { }

  ngOnInit(): void {

  }
  microsoftSignIn() {

    this._msalService.loginPopup().subscribe((userData: AuthenticationResult) => {

      if (userData['accessToken']) {
        this.userLogin(userData.idTokenClaims['preferred_username'].toLowerCase());
      }
    });
  }

  userLogin(email) {
    // send the parameters if login successfull rediect to page else remain on this page

    const url = urls.prod_supp + urls.catalogUserLogin;
    const body = {
      'username': email,
      'domainType': 'ECS'
    };

    this.service.callRestful('POST', "https://catalogapi.moglix.com/catalog/user/login", body, null, null, null).subscribe(this.processLoginResult.bind(this));

  }

  processLoginResult(data) {
   
      this.googleAnalytics.trackEvent('button_click', 'User Interaction', 'Sign Up Button');
     
    
    if (data.status) {
      this.invalid = false;
      this.service.createSession('1', data.username, data.token);
      this.service.showMessage('success', 'Login successfully');
      this.service.offSpinner();
      this.router.navigateByUrl('/country');

    } else {
      this.invalid = true;
      this.service.showMessage('error', data.message);
      this.service.offSpinner();
    }

  }
}
